import React, { useCallback, useEffect, useState } from "react";
import { mapStrToNumberPrice } from "../../../logic/utils";
import { useParams } from "react-router-dom";
import MyCIAInput from "../../Input/MyCIAInput";
import { deleteWine, editWine, getWineCategoriesInfo, getWineById, setVisibilityWine, editWineCampari } from "../../../apis/modify-restaurant";
import WineYoutubeModal from "./Modal/WineYoutubeModal";
import DropdownItem from "./Dropdown/DropdownItem";
import WinePhotoModal from "./Modal/WinePhotoModal";
import ItemWineMenuArchieve from "./ItemWineMenuArchieve";
import { useTranslation } from "react-i18next";
import Archieve from "../../Icon/Archieve";
import Youtube from "../../Icon/Youtube";
import Cam from "../../Icon/Cam";
import Delete from "../../Icon/Delete";
import { myCIASelect } from "../../../style";
import Select from "react-select";
import { useDebounce } from "../../../logic/useDebounce";

const initialState = {
    name: "",
    category: "",
    price: "",
    description: "",
    served_to: "",
    stock: "",
    served_at: "",
    cellar: "",
    releaseYear: "",
    pricePerGlass: "",
};

function ItemWineMenuModify(props) {
    const { t } = useTranslation();
    const { restaurantId } = useParams();
    const { isArchieve, categoryName, wineId, name, price, isCampari, isPago, isSanbitter, weight, onDelete, onRefresh, onChangeWineNameAndPrice } = props;

    const [wineData, setWineData] = useState({ ...initialState, name, price: mapStrToNumberPrice(price), isNotCampari: isCampari !== 1, isNotPago: isPago !== 1, isNotSanbitter: isSanbitter !== 1 });
    const [wineInfo, setWineInfo] = useState({ rvi_id: wineId, rvi_weight: weight });
    const [wineCategoryOptions, setWineCategoryOptions] = useState([]);
    const [selectedCategoryOption, setSelectedCategoryOption] = useState();
    const [isModifyEnabled, setIsModifyEnabled] = useState(false);
    const [isOptionEnabled, setIsOptionEnabled] = useState(false);

    const getSingleDrink = useCallback(async () => {
        const resCategories = await getWineCategoriesInfo(restaurantId);
        const options = resCategories?.results?.map((it) => ({ label: it.text, value: it.id })) ?? [];
        setWineCategoryOptions(options);

        const res = await getWineById(restaurantId, wineId);
        const drink = res.drink;
        if (drink) {
            setWineInfo(res.drink);
            let selectedCategory = options.find((it) => it.label == drink.rvi_categoria);
            setSelectedCategoryOption(selectedCategory);
            setWineData({
                name: drink.rvi_nome,
                category: selectedCategory.label,
                categoryId: selectedCategory.value,
                isNotCampari: drink.rvi_campari !== 1,
                isNotPago: drink.rvi_pago !== 1,
                isNotSanbitter: drink.rvi_sanbitter !== 1,
                price: mapStrToNumberPrice(drink.rvi_prezzo),
                description: drink.rvi_descrizione,
                served_at: drink.rvi_bevanda_per,
                stock: drink.rvi_scorte || "",
                cellar: drink.rvi_cantina || "",
                releaseYear: drink.rvi_anno || "",
                pricePerGlass: drink.rvi_prezzo_calice || "",
                solfiti: drink.rvi_solfiti,
            });
        }
    }, [restaurantId, wineId]);

    const handleWineUpdate = useCallback(async () => {
        const editFunction = wineData.isNotCampari ? editWine : editWineCampari;
        await editFunction(restaurantId, wineId, wineData);
    }, [restaurantId, wineId, wineData]);

    const debouncedUpdateWine = useDebounce(handleWineUpdate);

    const debouncedOnRefresh = useDebounce(() => {
        onRefresh();
    });

    const onChangeHandler = ({ target }) => {
        setWineData((wineData) => ({
            ...wineData,
            [target.name]: target.value,
        }));

        debouncedUpdateWine();
    };

    const onChangeOption = async (target) => {
        setWineData({
            ...wineData,
            categoryId: target.value,
            category: target.label,
        });
        setSelectedCategoryOption(target);

        debouncedUpdateWine();
        debouncedOnRefresh();
    };

    const onBlurHandler = async () => await handleWineUpdate();

    const onDeleteWine = async () => {
        const res = await deleteWine(restaurantId, wineId);
        if (res.outcome) onDelete(wineId);
    };

    const onArchiveWine = async () => {
        const res = await setVisibilityWine(restaurantId, wineInfo.rvi_id, 0);
        if (res.outcome) {
            onDelete(wineId);
        }
    };

    return (
        <div className="ItemWineMenuModify">
            {!isArchieve ? (
                <div className="flex items-center justify-between border-b border-mycia-dimGray px-mobile py-4">
                    <p className="font-light">{wineData.name}</p>

                    {isModifyEnabled ? (
                        <p className="cursor-pointer text-lg font-semibold text-mycia-orangeRed underline" onClick={() => setIsModifyEnabled(!isModifyEnabled)}>
                            Chiudi
                        </p>
                    ) : (
                        <div className="flex items-center">
                            <p className="font-light">{mapStrToNumberPrice(wineData.price)}€</p>

                            <svg
                                id="Livello_2"
                                className="icon-mycia ml-3 cursor-pointer"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 23.41 24"
                                onClick={() => {
                                    setIsModifyEnabled(!isModifyEnabled);
                                    getSingleDrink();
                                }}
                            >
                                <g id="Livello_1-2">
                                    <path
                                        id="Tracciato_399"
                                        d="M0,22.95c0-.57,.46-1.04,1.03-1.05H22.36c.58,0,1.04,.48,1.04,1.05,0,.57-.47,1.04-1.04,1.05H1.03C.46,23.98,0,23.52,0,22.95Zm1.14-3.77l1.64-1.7c-.41-.47-.49-1.13-.21-1.68l1.56-3.66c.1-.24,.24-.45,.42-.63L15.33,.8c.82-.96,2.27-1.08,3.23-.26,.1,.08,.19,.18,.27,.28l2.46,2.46c.97,.82,1.09,2.27,.27,3.24-.08,.1-.17,.19-.27,.27l-10.77,10.71c-.17,.19-.38,.33-.62,.42l-3.67,1.54c-.55,.28-1.22,.19-1.68-.22l-.74,.58c-.2,.18-.46,.28-.73,.25H1.53c-.28,.05-.54-.14-.58-.42-.03-.18,.04-.37,.19-.48Zm6.98-2.77l-2.46-2.48-.93,2.15c-.09,.17-.06,.38,.09,.5l.65,.64c.12,.15,.33,.2,.5,.1l2.16-.91Zm1.6-1.05l7.96-7.91-3-3.02-7.97,7.92,3.01,3.01ZM15.99,3.11l3,3.01,.65-.65c.23-.17,.28-.5,.11-.73-.02-.03-.05-.06-.09-.09l-2.19-2.21c-.19-.22-.53-.25-.75-.06-.03,.02-.06,.05-.08,.08l-.65,.65Z"
                                    />
                                </g>
                            </svg>
                        </div>
                    )}
                </div>
            ) : (
                <ItemWineMenuArchieve wineId={wineId} wine={wineData} onDelete={onDelete} />
            )}

            <div className={`${!isModifyEnabled && `hidden`} pb-8`}>
                <div className="px-mobile py-6">
                    <div className="button-mycia-white relative">
                        <div className="flex cursor-pointer select-none items-center justify-between" onClick={() => setIsOptionEnabled(!isOptionEnabled)}>
                            <p className="font-semibold">OPZIONI</p>

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className={`h-6 w-6 transition-all duration-300 ${isOptionEnabled ? `rotate-180` : `rotate-0`}`}>
                                <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                            </svg>
                        </div>

                        <div className={`z-50 absolute left-0 mt-6 flex w-full flex-col gap-y-5 rounded-lg p-4 shadow-mycia transition-all duration-150 ${isOptionEnabled ? `` : `hidden`} bg-white`}>
                            {wineData.isNotCampari && <DropdownItem label="Archivia" icon={<Archieve className="w-6 h-6" />} onClick={onArchiveWine} />}

                            {wineData.isNotCampari && wineData.isNotPago && wineData.isNotSanbitter && (
                                <WinePhotoModal restaurantId={restaurantId} wineId={wineId}>
                                    <DropdownItem label="Aggiungi foto bevanda" icon={<Cam className="w-6 h-6" />} />
                                </WinePhotoModal>
                            )}

                            <WineYoutubeModal restaurantId={restaurantId} wineId={wineId}>
                                <DropdownItem label="Aggiungi link YouTube" icon={<Youtube className="w-6 h-6" />} />
                            </WineYoutubeModal>
                            {wineData.isNotCampari && wineData.isNotPago && wineData.isNotSanbitter && <DropdownItem label="Elimina" icon={<Delete className="w-6 h-6" />} onClick={onDeleteWine} />}
                        </div>
                    </div>

                    {wineData.isNotCampari && wineData.isNotPago && wineData.isNotSanbitter && (
                        <>
                            <p className="mt-6 text-sm font-semibold uppercase">NOME BEVANDA</p>
                            <input
                                type="text"
                                name="name"
                                value={wineData.name}
                                onChange={onChangeHandler}
                                onBlur={(e) => {
                                    onBlurHandler(e);
                                    onChangeWineNameAndPrice(wineId, wineData.name, wineData.price);
                                }}
                                placeholder="Nome Bevanda"
                                className="input-mycia-secondary mt-2"
                            />

                            <p className="mt-6 text-sm font-semibold uppercase">NOME CATEGORIA</p>
                            <Select
                                className="mt-2 text-sm z-20"
                                styles={myCIASelect}
                                isClearable={false}
                                isSearchable={false}
                                options={wineCategoryOptions}
                                value={selectedCategoryOption}
                                onChange={onChangeOption}
                                placeholder="Nome Categoria (Es: Vini bianchi, Caffeteria...)"
                                noOptionsMessage={({ inputValue }) => (!inputValue ? "Nessuno" : "No results found")}
                            />
                            {/* <input type="text" name="category" value={wineData.category} onChange={onChangeHandler} onBlur={onBlurHandler} placeholder="Nome Categoria (Es: Vini bianchi, Caffeteria...)" className="input-mycia-secondary mt-2" /> */}
                        </>
                    )}

                    <div className="flex items-center mt-8">
                        <p className="text-lg font-semibold">Prezzo</p>
                        <input
                            type="number"
                            name="price"
                            step=".01"
                            value={wineData.price}
                            onChange={onChangeHandler}
                            onBlur={(e) => {
                                onBlurHandler(e);
                                onChangeWineNameAndPrice(wineId, wineData.name, wineData.price);
                            }}
                            placeholder="€"
                            className="input-mycia-secondary max-w-[10ch] ml-4"
                        />
                    </div>

                    {wineData.isNotCampari && wineData.isNotPago && wineData.isNotSanbitter && (
                        <>
                            <p className="mt-8 text-sm font-semibold uppercase">DESCRIZIONE BEVANDA</p>
                            <input type="text" name="description" value={wineData.description} onChange={onChangeHandler} onBlur={onBlurHandler} placeholder="Descrizione: dettagli del vino, particolarità della birra o del cocktail..." className="input-mycia-secondary mt-2" />

                            <p className="mt-6 text-lg font-semibold">Servito a</p>
                            <div className="flex items-center flex-wrap gap-x-4">
                                <MyCIAInput id={`served-dinner-${wineInfo.rvi_id}`} name="served_at" type="checkbox" value={0} className="mt-1" labelClassName="p-1" checked={wineData.served_at == 0} onChange={onChangeHandler}>
                                    <p className="text-lg font-semibold text-black">Pranzo</p>
                                </MyCIAInput>

                                <MyCIAInput id={`served-lunch-${wineInfo.rvi_id}`} name="served_at" type="checkbox" value={1} className="mt-1" labelClassName="p-1" checked={wineData.served_at == 1} onChange={onChangeHandler}>
                                    <p className="text-lg font-semibold text-black">Cena</p>
                                </MyCIAInput>

                                <MyCIAInput id={`served-both-${wineInfo.rvi_id}`} name="served_at" type="checkbox" value={2} className="mt-1" labelClassName="p-1" checked={wineData.served_at == 2} onChange={onChangeHandler}>
                                    <p className="text-lg font-semibold text-black">Entrambi</p>
                                </MyCIAInput>
                            </div>
                        </>
                    )}

                    {/* COMMENTED THE FEATURE BY DAVIDE REQUEST */}
                    {/* <div className="flex items-center my-6">
                        <p className="flex-shrink-0 text-lg font-semibold">Scorte in magazzino</p>
                        <input type="number" name="stock" value={wineData.stock} onChange={onChangeHandler} onBlur={onBlurHandler} placeholder="€" className="input-mycia-secondary max-w-[10ch] ml-4" />
                    </div> */}
                </div>

                <hr className="border-t-2 border-mycia-dimGray" />

                {wineData.isNotCampari && wineData.isNotPago && wineData.isNotSanbitter && (
                    <div className="px-mobile">
                        <p className="mt-10 text-lg font-semibold">Nel caso si inserisca un vino compilare i seguenti campi, altrimenti lasciare vuoto.</p>

                        <p className="mt-6 text-sm font-semibold uppercase">CANTINA</p>
                        <input type="text" name="cellar" value={wineData.cellar} onChange={onChangeHandler} onBlur={onBlurHandler} placeholder="-" className="input-mycia-secondary mt-2" />

                        <p className="mt-6 text-sm font-semibold uppercase">ANNO BOTTIGLIA</p>
                        <input type="number" name="releaseYear" value={wineData.releaseYear} onChange={onChangeHandler} onBlur={onBlurHandler} placeholder="-" className="input-mycia-secondary mt-2" />

                        <div className="flex items-center mt-6">
                            <p className="flex-shrink-0 text-lg font-semibold">Prezzo al calice</p>
                            <input type="number" name="pricePerGlass" value={mapStrToNumberPrice(wineData.pricePerGlass)} onChange={onChangeHandler} onBlur={onBlurHandler} placeholder="-" className="input-mycia-secondary max-w-[10ch] ml-4" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ItemWineMenuModify;
